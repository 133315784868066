<template>
  <div v-if="context == 'preview'">
    <component
      v-for="(item, index) in block.children"
      :key="item.id"
      v-model="block.children[index]"
      :is="getType(item.blockType)"
      context="preview"
    />
  </div>

  <div v-else>
    <div class="mb-4">
      <rw-text v-model="block.title" placeholder="Enter the title" label="Title" />
    </div>

    <p v-if="!block.children.length" class="m-0">Add a container</p>
    <draggable
      :list="block.children"
      handle=".block-handle"
      class="pb-6"
      :group="{ name: 'section', put: true }"
      item-key="_id"
    >
      <template #item="{ element, index }">
        <content-builder-item
          v-model="block.children[index]"
          :includePresets="true"
          :tabs="true"
          @update:value="updateItem($event, index)"
          @remove-item="block.children.splice(index, 1)"
          @load-block="loadBlock($event, index)"
        />
      </template>
    </draggable>

    <content-block-picker
      sectionOptions="contentContainer"
      blockOptions="contentBlocks"
      @add-block="block.children.push($event)"
    />
  </div>
</template>

<script>
import { reactive } from 'vue';

export default {
  name: 'ContentSection',
  props: ['modelValue', 'context', 'blockOptions'],
  data() {
    return {
      block: reactive(this.modelValue),
    }
  },
  watch: {
    block: {
      deep: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    updateItem($event, idx) {
      this.block.children[idx] = $event;
      //this.$set(this.block.children, idx, $event)
    },
    loadBlock($event, idx) {
      this.block.children[idx] = $event.content;
      //this.$set(this.block.children, idx, $event.content)
    },
    getType(blockType) {
      let blockName = blockType
        .match(/\w+/)[0]
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
      return 'rw-' + blockName
    },
  },
}
</script>