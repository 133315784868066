<template>
  <div v-if="context == 'preview'">
    <h3>{{ block.title }}</h3>
    <p>{{ block.text }}</p>
    <div>
      <label>{{ block.selectLabel }}</label>
      <select>
        <option value="test" selected>{{ block.defaultOption }}</option>
      </select>
    </div>
  </div>

  <div v-else-if="!isLoading">
    <rw-select :options="options" v-model="block.selectorType" label="Selector Type" />

    <rw-text v-model="block.title" label="Title" />
    <rw-textarea v-model="block.text" label="Text" />

    <rw-select v-model="block.headerColor" :include-null="true" label="Header Color" :options="headerColorOptions" /><br/>
    <rw-select v-model="block.borderColor" :include-null="false" label="Border Color" :options="borderColorOptions" /><br/>

    <rw-text v-model="block.selectLabel" label="Screen Reader Select Label" />
    <rw-text
      v-model="block.defaultOption"
      label="Default Option"
      help="Enter the first value in the select box"
    />
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>

import { ALL_GLOBAL_LINKS } from '@/graphql/global-links';
import { globalBorderColorOptions, globalHeaderColorOptions } from '@/settings/blocks';

export default {
  name: 'LinkSelector',
  mixins: [BlockMixin],
  data() {
    return {
      isLoading: true,
      globalLinks: {},
      headerColorOptions: globalHeaderColorOptions,
      borderColorOptions: globalBorderColorOptions,
      options: {
        // refundTracker: 'Refund Tracker',
        // stateList: 'State List',
      },
    }
  },
  apollo: {
    globalLinks: {
      query: ALL_GLOBAL_LINKS,
      result() {
        this.globalLinks.forEach((link) => {
          this.options[link.slug] = link.link_type;
        });

        this.isLoading = false;
      }
    },
  },
}
</script>

<style>
</style>