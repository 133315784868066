<template>
  <div class="hero-block">
    <div v-show="context == 'standalone'" v-if="isEditable">
      <light-switch class="mb-2" v-model="block.includeHero"><template #label>Include Hero?</template></light-switch>
    </div>

    <p class="h4" v-if="context == 'standalone' && block.includeHero">Preview</p>
    
    <div
      v-if="context == 'preview' || (context == 'standalone' && block.includeHero)"
      class="relative p-16"
      :style="getSiteColors"
      :class="{
        'bg-image-container': hasBgImage,
        'bg-dark': block.bgShade == 'bg-dark',
        'bg-light': block.bgShade == 'bg-light',
        'bg-gray-300': block.bgStyle == 'bg-subtle'
      }"
    >
      <div
        v-if="hasBgImage"
        class="bg-image"
        :class="`overlay-${block.bgImage.overlay}`"
        v-bind:style="{ backgroundImage: 'url(' + block.bgImage.src + ')' }"
      ></div>
      <div
        class="relative md:max-w-2xl"
        :class="[
          hasBgImage && block.bgShade == 'bg-dark' ? 'text-white' : null,
          hasBgImage && block.bgShade == 'bg-light' ? 'text-black' : null,
          (block.textAlign == 'center' || block.textAlign == 'centerTop' || block.textAlign == 'centerBottom') ? 'text-center mx-auto' : null,
          (block.textAlign == 'right' || block.textAlign == 'rightTop' || block.textAlign == 'rightBottom') ? 'text-right ml-auto' : null,
          block.includeShadowText ? 'hero-shadow' : null
        ]"
      >
        <p v-show="block.preTitle" v-html="block.preTitle"></p>
        <h2 class="title" v-show="block.title" v-html="block.title"></h2>
        <h3 v-show="block.subTitle" v-html="block.subTitle"></h3>
        <p v-show="block.text" v-html="block.text"></p>
        <rw-link v-model="block.link" context="preview" />
      </div>
    </div>

    <hr class="border my-4" v-if="context == 'standalone' && block.includeHero" />

    <div v-if="(context == 'settings' || (context == 'standalone' && block.includeHero)) && isEditable">
      <p class="font-bold mt-4">Hero Settings</p>

      <div class="space-y-2 w-2/5 settings-block">
        <rw-select
          :options="{ 
            'angled-image': 'Angled Image Right',
            'angled-image-left': 'Angled Image Left',
            'full-page': 'Full Page Image', 
            'full-page-text': 'Full Page Text (no image)', 
            'full-page-parallax': 'Full Page Parallax', 
          }"
          v-model="block.blockVariant"
          :includeNull="true"
          label="Variant"
          layout="inline"
        />

        <rw-select
          v-if="block.blockVariant && block.blockVariant.includes('full-')"
          :options="{ 
            'text-white': 'White',
            'text-primary': 'Primary', 
            'text-secondary': 'Secondary', 
            'text-third': 'Third', 
            'text-fourth': 'Fourth', 
          }"
          v-model="block.arrowColor"
          :includeNull="true"
          label="Down Arrow Color"
          layout="inline"
        />

        <rw-select
          :options="{ 
            'bg-subtle': 'Background - Subtle', 
            'bg-image': 'Background Image',
            'bg-primary': 'Background Primary Color',
            'bg-secondary': 'Background Secondary Color',
            'bg-third': 'Background Third Color',
            'bg-fourth': 'Background Fourth Color',
          }"
          v-model="block.bgStyle"
          label="Style"
          layout="inline"
        />

        <rw-select
          v-if="block.bgStyle != 'bg-subtle'"
          :options="{ 'bg-dark': 'Dark', 'bg-light': 'Light' }"
          v-model="block.bgShade"
          label="BG Shade"
          layout="inline"
        />

        <rw-select
          :options="{ 
            left: 'Center Align Left', 
            center: 'Center', 
            right: 'Center Align Right',
            leftTop: 'Top Left',
            centerTop: 'Top Center', 
            rightTop: 'Top Right',
            leftBottom: 'Bottom Align Left',
            centerBottom: 'Bottom Align Center', 
            rightBottom: 'Bottom Align Right',
          }"
          v-model="block.textAlign"
          :includeNull="true"
          label="Text Alignment"
          layout="inline"
        />

        <rw-select
          :options="{ default: 'Default', full: 'Full' }"
          v-model="block.textWidth"
          label="Text Width"
          layout="inline"
        />

        <rw-image
          v-if="block.bgStyle == 'bg-image'"
          v-model="block.bgImage"
          context="settings"
          label="Background Image"
          :includeOverlay="true"
          :includeAlt="false"
          :includeSrc="false"
          :includeStyle="false"
          :includeCrop="true"
        />
      </div>

      <div>
        <p class="font-bold mt-4">Hero Text</p>
        <div class="space-y-2">
          <light-switch class="mb-2" v-model="block.includeShadowText"><template #label>Include darker shadow to text?</template></light-switch>
          <rw-text v-model="block.preTitle" placeholder="Enter the PreTitle" label="PreTitle" />
          <rw-text v-model="block.title" placeholder="Enter the title" label="Title" />
          <rw-text v-model="block.subTitle" placeholder="Enter the SubTitle" label="SubTitle" />
          <rw-textarea v-model="block.text" placeholder="Enter the text" label="Text" />
        </div>
      </div>

      <rw-link v-model="block.link" context="settings" settingsLabel="Hero Link" />

      <modal-dialog v-if="block.bgImage" :show="showModal" @close="showModal = false">
        <template #header><h3 slot="header">Image Details</h3></template>
        <p class="m-0 break-all" v-for="(attribute, id) in block.bgImage.cloudinary" :key="id">
          <strong>{{ id }}:</strong>
          {{ attribute }}
        </p>
      </modal-dialog>
    </div>
  </div>
</template>

<script>
import { hero, image } from '@/settings/blocks'
import { SITE_SETTINGS } from '@/graphql/sites'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'Hero',
  props: ['context', 'editable', 'modelValue', 'siteId'],
  data() {
    return {
      showModal: false,
      block: this.setDefault(),
      colors: {
        'primary': null,
        'secondary': null,
        'third': null,
        'fourth': null,
      },
    }
  },
  computed: {
    hasBgImage() {
      return this.block.bgStyle == 'bg-image' && this.block.bgImage.src
    },
    isEditable() {
      return this.editable || this.editable == undefined ? true : false;
    },
    getSiteColors() {
      if (this.block.bgStyle == 'bg-primary') {
        return 'background-color:'+this.colors.primary;
      }
      
      if (this.block.bgStyle == 'bg-secondary') {
        return 'background-color:'+this.colors.secondary;
      }

      if (this.block.bgStyle == 'bg-third') {
        return 'background-color:'+this.colors.third;
      }

      if (this.block.bgStyle == 'bg-fourth') {
        return 'background-color:'+this.colors.fourth;
      }

      return '';
    }
  },
  mounted() {
    if (this.siteId) {
      this.$apollo.addSmartQuery('site', {
          query: SITE_SETTINGS,
          variables() {
            return {site_id: this.siteId}
          },
          result({data}) {
            this.colors.primary = data.site.siteSettings.primary_color;
            this.colors.secondary = data.site.siteSettings.secondary_color;
            this.colors.third = data.site.siteSettings.third_color;
            this.colors.fourth = data.site.siteSettings.fourth_color;
          }
        });
    }
  },
  
  watch: {
    block: {
      deep: true,
      handler(newVal) {
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    setDefault() {
      let obj = cloneDeep(hero)
      
      return this.context == 'standalone' ? Object.assign({}, obj, this.modelValue) : this.modelValue || obj
    },
    removeImage() {
      this.block.bgImage = cloneDeep(image)
    },
  },
}
</script>

<style lang="scss">
.bg-dark {
  color: #fff;
}

.bg-dark .btn {
  background-color: #fff;
  color: #000;
}

.title .sub-title {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
}

.bg-image-container {
  position: relative;
  color: #fff;

  [class*='overlay-']::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .overlay-subtle::before {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .overlay-dark::before {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .overlay-darker::before {
    background-color: rgba(0, 0, 0, 0.45);
  }

  .overlay-darkest::before {
    background-color: rgba(0, 0, 0, 0.65);
  }

  .overlay-white::before {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .overlay-whiter::before {
    background-color: rgba(255, 255, 255, 0.45);
  }

  .overlay-whitest::before {
    background-color: rgba(255, 255, 255, 0.65);
  }
}
.bg-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
}

.hero-shadow {
  text-shadow: 2px 2px 5px #000000 !important;
}


</style>
